import styled from "styled-components"

const Input = styled.input`
  height: 50px;
  margin-bottom: 10px;
  width: 100%;
  padding-left: 10px;
  color: black;
  border-radius: 5px;
  box-shadow: #00000044 0px 0px 2px;
  font-size: 16px;
  user-select: initial;

  :disabled {
    opacity: 0.6;
    background: darkgray;
  }
`

export default Input
