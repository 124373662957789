import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { css } from "styled-components"
import { useFlexSearch } from "react-use-flexsearch"

import ContentLayout from "../../components/ContentLayout"
import Input from "../../components/Input"
import Label from "../../components/Label"

import Icon from "../../svg/menu/sopron.svg"

const EpuletekPage = ({
  data: {
    cover: { childImageSharp: cover },
    localSearch: { index, store },
  },
}) => {
  const [search, setSearch] = useState("")
  const results = useFlexSearch(search, index, store, {
    limit: 10,
  })

  return (
    <ContentLayout icon={Icon} title="Terem, Oktató kereső" cover={cover.fluid}>
      <Label>Keresendő kifejezés</Label>
      <Input
        value={search}
        onChange={e => setSearch(e.target.value)}
        placeholder="pl.: 7. terem"
      />
      {results.map(({ building, nodeId, people, name, room }) => (
        <Link
          css={css`
            width: 100%;
            height: 50px;
            position: relative;
            font-size: 14px;
            appearance: none;
            border-radius: 13px;
            color: #707070;
            background: #c5ccce55;
            box-shadow: 0px 3px 6px #00000029;
            cursor: pointer;
            outline: 0;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            -webkit-touch-callout: none;
            text-decoration: none;
            padding: 10px;
            display: flex;
            text-transform: uppercase;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
          `}
          key={nodeId}
          to={`${building}/${room}`}
        >
          {building}-{room}: {name}: {people.map(person => person)}
        </Link>
      ))}
    </ContentLayout>
  )
}

export default EpuletekPage

export const query = graphql`
  query epuletek {
    cover: file(relativePath: { eq: "covers/sopron.png" }) {
      childImageSharp {
        fluid(cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    localSearch: localSearchRooms {
      index
      store
    }
  }
`
